<template>
    
  <div>
    <Card class="mb-5">
      <template #title>
        <Toolbar>
          <template #start>
            <back-button />
            <h3 class="ml-2">
              {{ $t("create_players_ava") }}
            </h3>
          </template>
          <template #end>
            <Button
              :label="$t('save')"
              icon="pi pi-save"
              class="p-button-sm p-button-success"
              :loading="loadingFlag"
              :disabled="loadingFlag"
              @click="toCreateAva"
            />
          </template>
        </Toolbar>
      </template>
      <template #content>
        <div class="rebuild-ava">
          <div class="rebuild-ava__wrapper">
            <!-- <img :src="createAva.preview" :alt="createAva.name"/> -->
            <div class="rebuild-ava__content">
              <div class="rebuild-ava__item">
                <button type="button" class="rebuild-ava__image" :class="{ '_no-photo' : !createAva.preview}" @click="modalImages = true">
                    <div class="no-photo-plus" v-if="!createAva.preview">
                        <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                    </div>
                    <img ref="imagePreview"
                       :src="createAva.preview"
                        :alt="createAva.name" preview />
                        <!-- <span class="image-size">
                            {{ createAva.size }}
                        </span> -->
                        <span class="image-change" v-if="createAva.preview">
                            <v-icon color="var(--surface-0)">mdi-camera</v-icon>
                        </span>
                </button>
              </div>
            </div>
            <div class="rebuild-ava__actions">
              <Skeleton
                width="4rem"
                height="2rem"
                v-if="loadingFlag"
              ></Skeleton>
              <div
                class="field-radiobutton _no-icon d-flex align-center"
                v-else
              >
                <Checkbox
                  id="type"
                  name="is_default"
                  v-model="createAva.is_default"
                  :binary="true"
                  />
                  <!-- :disabled="createAva.is_default" -->
                <label for="start_ava" class="ml-1">
                  {{ $t("started_ava") }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Header"
          class="header-modal"
          :visible.sync="modalImages"
          :containerStyle="{ 'max-width': '350px' }"
          :modal="true"
        >
          <template #header>
            <div class="header-modal__wrapper">
              <div class="header-modal__item">
                <div class="header-modal__logo">
                  <img
                    src="@/assets/images/ADMIN_LOGO.png"
                    width="75"
                    alt="logo"
                  />
                </div>
              </div>
              <div class="header-modal__item">
                <h3 class="header-modal__title">
                  {{ $t("edit_players_ava") }}
                </h3>
              </div>
            </div>
          </template>
          <div class="header-content">
            <div class="text-left">
              <h3>
                {{ $t("ava") }}
              </h3>
            </div>
            <div class="text-left" v-html="$t('ava_descr')"></div>
          </div>
          <Divider class="my-3" />
          <span
            v-if="noImageError"
            class="c-red d-flex justify-start font-weight-bold mb-3"
          >
            {{ $t("select_at_least_one") }}
          </span>
          <div class="header-content">
            <div class="header-content-images">
              <div class="header-content-images__item">
                <div
                  class="header-content-images__content header-content-images__content_big"
                >
                  <div
                    class="header-content-images__image"
                    v-if="createAva.image"
                  >
                    <img :src="createAva.image" alt="image" />
                    <div class="delete-close" @click="deleteImage('image')">
                      <v-icon color="var(--surface-100)">mdi-close</v-icon>
                    </div>
                  </div>
                  <div
                    @click="triggerImageInput"
                    class="header-content-images__square"
                    v-else
                  >
                    <input
                      type="file"
                      ref="imagePreview"
                      accept="image/png, image/jpeg, image/svg+xml"
                      style="display: none"
                      id="trigger-image-id"
                      @change="uploadImage($event, 'image')"
                    />
                    <div class="no-photo-plus">
                      <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                    </div>
                  </div>
                </div>
                <div class="header-content-images__text">(1)</div>
              </div>
              <div class="header-content-images__item">
                <div
                  class="header-content-images__content header-content-images__content_small"
                >
                  <div
                    class="header-content-images__image"
                    v-if="createAva.preview"
                  >
                    <img :src="createAva.preview" alt="preview" />
                    <div class="delete-close" @click="deleteImage('preview')">
                      <v-icon color="var(--surface-100)">mdi-close</v-icon>
                    </div>
                  </div>
                  <div
                    @click="triggerPreviewInput"
                    class="header-content-images__square"
                    v-else
                  >
                    <input
                      type="file"
                      ref="imagePreview"
                      accept="image/png, image/jpeg, image/svg+xml"
                      style="display: none"
                      id="trigger-preview-id"
                      @change="uploadImage($event, 'preview')"
                    />
                    <div class="no-photo-plus">
                      <v-icon color="var(--surface-900)">mdi-plus</v-icon>
                    </div>
                  </div>
                </div>
                <div class="header-content-images__text">(2)</div>
              </div>
            </div>
          </div>
          <template #footer>
            <div class="d-flex align-center">
              <Button
                :label="$t('confirm')"
                icon="pi pi-save"
                class="p-button-sm p-button-success mt-2 mr-0"
                style="width: 100%"
                @click="confirmImages"
              />
            </div>
          </template>
        </Dialog>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  name: "CreatePlayerAvatars",
  data() {
    return {
      modalImages: false,
      loadingFlag: true,
      noImageError: false,
      noNewImageError: false,
      holdPreview: "",
      createAva: {
        preview_file: null,
        image_file: null,
        image: null,
        preview: null,
        is_default: false,
        // name: '',
        // descr: '',
        // isStarted: 'start_ava',
      },
    };
  },
  watch: {
    noNewImageError(newVal) {
      if (newVal) {
        this.modalImages = true;
        this.$toast.add({
          severity: "info",
          summary: this.$t("add_photo_to_save"),
          life: 10000,
        });
        this.noNewImageError = false;
      }
    },
  },
  computed: {

  },
  async mounted() {
    this.loadingFlag = false;
  },
  methods: {
    confirmImages() {
      this.noImageError = false;
      if (!this.createAva.preview && !this.createAva.image) {
        this.noImageError = true;
        return;
      }
      if (!this.createAva.preview) {
        this.createAva.preview = this.createAva.image;
        this.createAva.preview_file = this.createAva.image_file;
      } else if (!this.createAva.image) {
        this.createAva.image = this.createAva.preview;
        this.createAva.image_file = this.createAva.preview_file;
      }
      this.modalImages = false;
    },
    async getImageInfo() {
      for (const ava of this.avatars) {
        const img = new Image();
        const avaPreview = ava.preview;
        console.log("ava", ava);
        img.src = ava.preview;
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });
        console.log("img", img.weight);
        const size = `${img.width}x${img.height}`;
        console.log("size", size);
        const weight = `${Math.round(img.src.length / 1024)} KB`;
        ava.size = size;
        ava.weight = weight;
      }
    },
    async toCreateAva() {
      const create = this.createAva;
      if (!create.image_file || !create.preview_file) {
        this.noNewImageError = true;
        return;
      }
      this.loadingFlag = true;
      try {
        const form = new FormData();
        form.append("is_default", create.is_default);
        form.append("image", create.image_file);
        form.append("preview", create.preview_file);
        await this.$store.dispatch("playerAvatars/createAvatar", form);
        this.$toast.add({
          severity: "success",
          summary: this.$t("ava_created"),
          life: 4000,
        });
        await this.$store.dispatch("playerAvatars/getAvatars");
        await this.$router.push("/avatars/view");
      } catch (error) {
        console.log('error = ', error);
        this.$toast.add({
          severity: "error",
          summary: this.$t("just_error"),
          life: 4000,
        });
      } finally {
        this.loadingFlag = false;
      }
    },
    triggerImageInput() {
      document.getElementById("trigger-image-id").click();
    },
    triggerPreviewInput() {
      document.getElementById("trigger-preview-id").click();
    },
    uploadImage(event, type) {
      const file = event.target.files[0];
      console.log('file', file);
        if (file && file.size <= 500000) {
          if (type === "preview") {
          const imageUrl = URL.createObjectURL(file);
          this.createAva.preview = imageUrl;
          this.createAva.preview_file = file;
        } else if (type === "image") {
          const imageUrl = URL.createObjectURL(file);
          this.createAva.image = imageUrl;
          this.createAva.image_file = file;
        }
      } else{
        this.$toast.add({
          severity: 'error', 
        summary: this.$t('upload_error'), 
        detail: this.$t('upload_less_image'), 
        life: 8000
      });
      this.deleteImage(type);
      }
    },
    deleteImage(type) {
      if (type === "image") {
        URL.revokeObjectURL(this.createAva.image);
        this.createAva.image = null;
        this.createAva.image = null;
        this.createAva.image_file = null;
      } else if (type === "preview") {
        URL.revokeObjectURL(this.createAva.preview);
        this.createAva.preview = null;
        this.createAva.preview_file = null;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("setPageName", "players_ava");
    });
  },
};
</script>

<style lang="scss" scoped></style>
